import './App.css';
import { Router } from "@reach/router"
import Content from './content'
import Form from './form'
import { RouteComponentProps } from "@reach/router";

let Home = (props: RouteComponentProps) => <div>loading...</div>

function App() {
  return (
    <Router>
      <Home path="/" />
      <Content path="content/:contentId" contentId="" />
      <Form path="form/:formId" formId="" />
    </Router>
  );
}

export default App;
