import { Row } from 'antd';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react'
import { RouteComponentProps } from "@reach/router";
import "../css/html-preview.css"
import { supabase } from '../supabaseClient';
import { contents } from '../errk-api';
export interface UserContentProps extends RouteComponentProps {
    contentId: string;
}

export type ProfileType = {
    userId: string;
    displayName: string;
    pictureUrl?: string;
    statusMessage?: string;
}

export default function Content(props: UserContentProps) {
    const [content, setContent] = useState<contents | undefined>(undefined)
    const [, setChatUserId] = useState('')

    useEffect(() => {
        (async () => {
          const liff = (await import('@line/liff')).default
          try {
            console.log('init liff')
            await liff.init({ liffId: '1656147561-ekvNqGpJ' });
            console.log('init liff done')
          } catch (error) {
            console.error('liff init error', error)
          }
        })()
      }, [])

    useEffect(() => {
        (async () => {
            console.log('load content')
            const { data } = await supabase.from('contents').select().eq('id', props.contentId).single()
            setContent(data);
        })()
    }, [props.contentId])

    useEffect(() => {
        (async () => {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            setChatUserId(params.get('chatUserId') || '')
        })()
    }, [])

    return <Row justify="center" style={{ minHeight: '85vh' }}>
        <div className="html-view">
            {parse(content?.htmlData || "")}
        </div>
    </Row>
}
