import { Button, Typography, message } from 'antd';
import FormRender, { useForm, Error } from 'form-render';
import Mustache from 'mustache';
import { evaluate, isNumeric, round } from 'mathjs'
import { RouteComponentProps } from "@reach/router";
import { useEffect, useState } from 'react'
import 'antd/dist/antd.css';
import liff from '@line/liff';
import { supabase } from '../supabaseClient';
import { forms, formResults } from '../errk-api';
export interface UserFormProps extends RouteComponentProps {
    formId: string;
}

export type ProfileType = {
    userId: string;
    displayName: string;
    pictureUrl?: string;
    statusMessage?: string;
}

export type UserTimeRelatedInput = {
    typeKey: string;
    value: any;
    valueType: "string" | "number" | "bool" | "date";
    recordTime: Date;
    createAt: Date;
};

const { Title } = Typography;

const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Form = (props: UserFormProps) => {
    const [form, setForm] = useState<forms | undefined>(undefined)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [chatUserId, setChatUserId] = useState('')
    const [formResultId, setFormResultId] = useState('')
    const formObj = useForm();

    useEffect(() => {
        (async () => {
            const { data } = await supabase.from('forms').select().eq('id', props.formId).single()
            setForm(data);
        })()
    }, [props.formId])

    useEffect(() => {
        if(formResultId==='')
            return
        (async () => {
            const { data } = await supabase.from<formResults>('formResults').select().eq('id', formResultId).single()
            formObj.setValues(data?.response);
        })()
    }, [formResultId, formObj])

    useEffect(() => {
        (async () => {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            setChatUserId(params.get('chatUserId') || '')
            setFormResultId(params.get('formResultId') || '')
        })()
    }, [])

    useEffect(() => {
        (async () => {
          const liff = (await import('@line/liff')).default
          try {
            console.log('init liff')
            await liff.init({ liffId: '1656147561-ekvNqGpJ' });
            console.log('init liff done')
          } catch (error) {
            console.error('liff init error', error)
          }
        })()
      }, [])

    const getAllFormData = (obj: object, res: { [k: string]: string | number }) => {
        for (const [key, value] of Object.entries(obj)) {
            if (typeof value === 'number' || typeof value === 'string') {
                res[(key as string)] = value;
            } else if (typeof value === 'object') {
                getAllFormData(value, res);
            }
        }
    }

    const watch = {
        '#': (val: any) => {
            for (const [key, value] of Object.entries(formObj.flatten)) {
                let calculated = (value as any).schema.calculated as string
                const roundNumber = (value as any).schema.roundNumber as number
                const visibleOp = (value as any).schema.visibleOp as ">" | "=" | "<" | "~"
                const visibleValue = (value as any).schema.visibleValue as number
                const visibleValue2 = (value as any).schema.visibleValue2 as number
                const widget = (value as any).schema.widget as string
                const hidden = (value as any).schema.hidden || false as boolean
                if (calculated) {
                    calculated = calculated.replace(/(\r\n|\n|\r)/gm, "");
                    try {
                        let data = {};
                        getAllFormData(formObj.formData, data)
                        const formula = Mustache.render(calculated, data, {}, ['${', '}$'])
                        let result = evaluate(formula);
                        console.log({
                            key,
                            formula,
                            result,
                            visibleOp,
                            visibleValue,
                            visibleValue2
                        })
                        if (isNumeric(roundNumber)) {
                            result = round(result, roundNumber)
                        }
                        if (widget !== "html")
                            formObj.setValueByPath(key, result);
                        if (visibleOp && visibleValue !== undefined) {
                            const lt = visibleOp === "<" && result < visibleValue;
                            const eq = visibleOp === "=" && result === visibleValue;
                            const gt = visibleOp === ">" && result > visibleValue;
                            const it = visibleOp === "~" && visibleValue2 && result >= visibleValue && result <= visibleValue2
                            if (lt)
                                console.log(key, result, "<", visibleValue)
                            if (eq)
                                console.log(key, result, "=", visibleValue)
                            if (gt)
                                console.log(key, result, ">", visibleValue)
                            if (it)
                                console.log(key, visibleValue, "<=", result, "<=", visibleValue2)
                            if (lt || eq || gt || it) {
                                if (hidden)
                                    setTimeout(
                                        () => formObj.setSchemaByPath(key, { hidden: false })
                                        , getRandomInt(500, 1000));
                            } else {
                                if (!hidden)
                                    setTimeout(
                                        () => formObj.setSchemaByPath(key, { hidden: true })
                                        , getRandomInt(100, 500));
                            }
                        }
                    } catch (e) {
                        // console.log(e)
                        // message.error("表單算式錯誤")
                    }
                }
            }
        },
    };

    const onFinish = async (data: any, error: Error[]) => {
        if (error.length > 0) {
            message.warning("問卷沒有填寫完喔！")
            console.log(error)
            return
        }
        setSubmitLoading(true)
        let userProperties: { [key: string]: any } = {}
        let userTimeRelatedDataList: UserTimeRelatedInput[] = []
        let recordDate = data?.recordDate
        try {
            recordDate = new Date(recordDate)
        } catch {
            recordDate = undefined
        }
        console.log(formObj.flatten)
        for (const [key, value] of Object.entries(data)) {
            if(formObj.flatten[key] === undefined){
                console.log(`schema for ${key} not found`)
                continue
            }
            const userPropertyKey = formObj.flatten[key].schema.userPropertyKey
            const userTimeRelatedDataKey = formObj.flatten[key].schema.userTimeRelatedDataKey
            const userTimeRelatedDataType = formObj.flatten[key].schema.type
            if (userPropertyKey) {
                userProperties[userPropertyKey] = value
            }
            if (userTimeRelatedDataKey) {
                userTimeRelatedDataList.push({
                    typeKey: userTimeRelatedDataKey,
                    value: value,
                    valueType: userTimeRelatedDataType,
                    recordTime: recordDate || new Date(),
                    createAt: new Date()
                })
            }
        }
        try {
            // const createAt = new Date();
            // await formResultUpdateRef.set({...result, createAt})
            // await userPropertiesRef.set({ ...userProperties, uid, organizationId: organizationId }, { merge: true })
            // await Promise.all(userTimeRelatedDataList.map((data) => {
            //     return userTimeRelatedDataRef.doc().set({ ...data, uid, organizationId: organizationId, formId: props.formId, formResultRef: formResultUpdateId })
            // }))
            await supabase.from('formResults').insert({chatUserId: chatUserId, formId: form?.id, response: data, organizationId: form?.organizationId} as formResults)
            message.success("成功送出 請點選右上角X離開", 30)
            setTimeout(() => liff.closeWindow(), 1000)
            setTimeout(() => window.close(), 500)
        } catch {
            message.error("出了一點錯誤~")
        }
        setSubmitLoading(false)
    }

    return (
        <div style={{ padding: '15px', backgroundColor: 'white', minHeight: '100vh', maxWidth: '500px', margin: '0 auto' }}>
            <Title level={4}>{form?.title}</Title>
            <FormRender form={formObj} schema={form?.schema} watch={watch} onFinish={onFinish} />
            <Button type="primary" onClick={formObj.submit} loading={submitLoading}>
                送出
            </Button>
        </div>

    )
}

export default Form;
